
function Error404 (props) {
  return (
    <div className='Error404'>
      <p>Error404: That page does not exist :(</p>
    </div>
  );
}

export default Error404;
