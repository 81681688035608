


function UserBoard (props) {
  
  return (
    <div className="UserBoard p-2">
      User board
    </div>
  )
}

export default UserBoard;
