
function UserGuide (props) {
  return (
    <div className='UserGuide'>
      User guide
    </div>
  );
}

export default UserGuide;
