

function Ticket (props) {
  return (
    <div className='Ticket'>
      Ticket
    </div>
  );
}

export default Ticket;
